import { get, flowRight } from 'lodash';
import { connect } from '../components/runtime-context';

import withHocName from './with-hoc-name';
import {
  getLayoutName,
  SEO_LAYOUT_TYPE_FALLBACK,
  POST_LIST_COMPONENT_MASONRY,
} from '@wix/communities-blog-client-common';
import { getLayoutType, isMasonryLayoutSupported } from '../selectors/layout-selectors';
import { getLayoutConfig } from '../services/layout-config';

import { getSection } from '../selectors/section-selectors';

const mapRuntimeToProps = (fallbackEnabled) => (state, { section, layoutType }) => {
  section = section || getSection(state, fallbackEnabled);
  layoutType = layoutType || getLayoutType(state, section);

  if (
    !isMasonryLayoutSupported(state) &&
    get(getLayoutConfig(layoutType), 'listComponentName') === POST_LIST_COMPONENT_MASONRY
  ) {
    layoutType = SEO_LAYOUT_TYPE_FALLBACK;
  }

  return {
    section,
    layoutType,
    layoutName: getLayoutName(layoutType),
  };
};

// prettier-ignore
export default function withLayoutProps({ fallbackEnabled = true } = {}) {
  return flowRight(
    connect(mapRuntimeToProps(fallbackEnabled)),
    withHocName('WithLayoutProps')
  );
}
